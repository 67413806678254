import React from "react";
import "./footer.scss";
import logo from "../../media/cropped-Shila-LOGO-PNG_Low-green.png";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img
            className="gambar-logo"
            src={logo}
            alt="logo-citraland-surabaya"
          />
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Shila At Sawangan</h2>
          </div>
        </div>

        <div className="container-deskripsi">
          <h1>Get In Touch</h1>
          <p>
            Jl. Raya Bojongsari No.53 35 m, Bojongsari Lama, Kec. Bojongsari,
            Kota Depok, Jawa Barat 16517
          </p>
          <div className="contact">Contact us : +6281317050838 (Tama)</div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">© 2024 All Rights Reserved.</div>
    </div>
  );
};

export default footer;
