import React from "react";
import "./home.scss";
import Header from "../section/header/header.jsx";
import Aboutus from "../section/aboutus/aboutus.jsx";
import Promo from "../section/promo/promo.jsx";
import Newlaunch from "../section/newlaunch/newlaunch.jsx";
import Rumah from "../section/rumah/rumah.jsx";
import Lokasi from "../section/lokasi/lokasi.jsx";
import Footer from "../section/footer/footer.jsx";
import Tombol from "../media/tombol.webp";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToHashElement from "./ScrollToHashElement.js";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20()%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Header />
      <Aboutus />
      <Promo />
      <Newlaunch />
      <Rumah />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={Tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
      <div className="wamobile">
        <button onClick={fungsiganteng} className="mobile">
          <div>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp Sekarang
          </div>
        </button>
      </div>
    </div>
  );
};

export default home;
