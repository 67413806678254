import Pavilion1 from "../../../media/produk/pavilion/Pav.png";
import Pavilion2 from "../../../media/produk/pavilion/pavilion (2).png";
import Pavilion3 from "../../../media/produk/pavilion/pavilion (3).png";
import Pavilion4 from "../../../media/produk/pavilion/pavilion (4).png";
import Pavilion5 from "../../../media/produk/pavilion/pavilion (5).png";
import Pavilion6 from "../../../media/produk/pavilion/pavilion (6).png";
import Pavilion7 from "../../../media/produk/pavilion/pavilion (7).png";

const pavilionimages = [
  Pavilion1,
  Pavilion2,
  Pavilion3,
  Pavilion4,
  Pavilion5,
  Pavilion6,
  Pavilion7,
];

export default pavilionimages;
