import Portico1 from "../../../media/produk/portico/portico.png";
import Portico2 from "../../../media/produk/portico/portico (2).png";
import Portico3 from "../../../media/produk/portico/portico (3).png";
import Portico4 from "../../../media/produk/portico/portico (4).png";
import Portico5 from "../../../media/produk/portico/portico (5).png";
import Portico6 from "../../../media/produk/portico/portico (6).png";
import Portico7 from "../../../media/produk/portico/portico (7).png";
import Portico8 from "../../../media/produk/portico/portico (8).png";
import Portico9 from "../../../media/produk/portico/portico (9).png";
import Portico10 from "../../../media/produk/portico/portico (10).png";
import Portico11 from "../../../media/produk/portico/portico (11).png";
import Portico12 from "../../../media/produk/portico/portico (12).png";

const porticoimages = [
  Portico1,
  Portico2,
  Portico3,
  Portico4,
  Portico5,
  Portico6,
  Portico7,
  Portico8,
  Portico9,
  Portico10,
  Portico11,
  Portico12,
];

export default porticoimages;
