import React from "react";
import "./produk3.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faHouse,
  faLocationDot,
  faShower,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Hannamimages from "./hannam.js";
import Porticoimages from "./portico.js";
import Sinsaimages from "./sinsa.js";

const produk3 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const hannamwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Hannam)%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const porticowa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Portico)%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const sinsawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20ruko%20ini%20(Sinsa)%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="produk3">
      <div className="kartu">
        <Slider {...settings}>
          {Hannamimages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Hannam ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah1">Hannam</div>
            <div className="sub-judul-rumah">
              <FontAwesomeIcon color="red" icon={faLocationDot} /> Shila, Depok
            </div>
            <div className="cicilan">
              <div className="start">Start From</div>
              <div className="det">
                <div className="angka">17</div>
                <div className="ket">Juta/Bulan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 144m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 206m²</span>
          <FontAwesomeIcon icon={faBed} />
          <span>: 4+1</span>
          <FontAwesomeIcon icon={faShower} />
          <span>: 4+2</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={hannamwa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
      <div className="kartu">
        <Slider {...settings}>
          {Porticoimages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Portico ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah1">Portico</div>
            <div className="sub-judul-rumah">
              <FontAwesomeIcon color="red" icon={faLocationDot} /> Shila, Depok
            </div>
            <div className="cicilan">
              <div className="start">Start From</div>
              <div className="det">
                <div className="angka">19</div>
                <div className="ket">Juta/Bulan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 185m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 189m²</span>
          <FontAwesomeIcon icon={faBed} />
          <span>: 4</span>
          <FontAwesomeIcon icon={faShower} />
          <span>: 4</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={porticowa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
      <div className="kartu">
        <Slider {...settings}>
          {Sinsaimages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Sinsa ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah1">Ruko Sinsa</div>
            <div className="sub-judul-rumah">
              <FontAwesomeIcon color="red" icon={faLocationDot} /> Shila, Depok
            </div>
            <div className="cicilan">
              <div className="start">Start From</div>
              <div className="det">
                <div className="angka">20</div>
                <div className="ket">Juta/Bulan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 100m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 225m²</span>
          <FontAwesomeIcon icon={faToilet} />
          <span>: 3</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={sinsawa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default produk3;
