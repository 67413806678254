import React from "react";
import "./produk1.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faHouse,
  faLocationDot,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Courtyardimages from "./courtyard.js";
import Verdantimages from "./verdant.js";
import Theterraceimages from "./theterrace.js";

const produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const courtyardwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Courtyard)%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const verdantwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Verdant)%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const theterracewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(The+terrace)%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="produk1">
      <div className="kartu">
        <Slider {...settings}>
          {Courtyardimages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Courtyard ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah1">Courtyard</div>
            <div className="sub-judul-rumah">
              <FontAwesomeIcon color="red" icon={faLocationDot} /> Shila, Depok
            </div>
            <div className="cicilan">
              <div className="start">Start From</div>
              <div className="det">
                <div className="angka">9</div>
                <div className="ket">Juta/Bulan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 112m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 109m²</span>
          <FontAwesomeIcon icon={faBed} />
          <span>: 3</span>
          <FontAwesomeIcon icon={faShower} />
          <span>: 4</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={courtyardwa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
      <div className="kartu">
        <Slider {...settings}>
          {Verdantimages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Courtyard ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah1">Verdant</div>
            <div className="sub-judul-rumah">
              <FontAwesomeIcon color="red" icon={faLocationDot} /> Shila, Depok
            </div>
            <div className="cicilan">
              <div className="start">Start From</div>
              <div className="det">
                <div className="angka">10</div>
                <div className="ket">Juta/Bulan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 98m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 110m²</span>
          <FontAwesomeIcon icon={faBed} />
          <span>: 3</span>
          <FontAwesomeIcon icon={faShower} />
          <span>: 3</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={verdantwa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
      <div className="kartu">
        <Slider {...settings}>
          {Theterraceimages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Courtyard ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah1">The Terrace</div>
            <div className="sub-judul-rumah">
              <FontAwesomeIcon color="red" icon={faLocationDot} /> Shila, Depok
            </div>
            <div className="cicilan">
              <div className="start">Start From</div>
              <div className="det">
                <div className="angka">11</div>
                <div className="ket">Juta/Bulan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 150m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 79m²</span>
          <FontAwesomeIcon icon={faBed} />
          <span>: 3</span>
          <FontAwesomeIcon icon={faShower} />
          <span>: 2</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={theterracewa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default produk1;
