import Theterrace1 from "../../../media/produk/theterrace/Terrace.png";
import Theterrace3 from "../../../media/produk/theterrace/terrace (3).png";
import Theterrace4 from "../../../media/produk/theterrace/terrace (4).png";
import Theterrace5 from "../../../media/produk/theterrace/terrace (5).png";
import Theterrace6 from "../../../media/produk/theterrace/terrace (6).png";
import Theterrace7 from "../../../media/produk/theterrace/terrace (7).png";
import Theterrace8 from "../../../media/produk/theterrace/terrace (8).png";
import Theterrace9 from "../../../media/produk/theterrace/terrace (9).png";
import Theterrace10 from "../../../media/produk/theterrace/terrace (10).png";

const theterraceimages = [
  Theterrace1,
  Theterrace3,
  Theterrace4,
  Theterrace5,
  Theterrace6,
  Theterrace7,
  Theterrace8,
  Theterrace9,
  Theterrace10,
];

export default theterraceimages;
