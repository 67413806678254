import React from "react";
import Produk1 from "./produk1/produk1.jsx";
import "./rumah.scss";
import Produk2 from "./produk2/produk2.jsx";
import Produk3 from "./produk3/produk3.jsx";

const rumah = () => {
  return (
    <div className="container-rumah">
      <div id="rumah" className="judul-rumah">
        OUR HOME
      </div>
      <div className="produk">
        <Produk1 />
        <Produk2 />
        <Produk3 />
      </div>
    </div>
  );
};

export default rumah;
