import React from "react";
import "./produk2.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faHouse,
  faLocationDot,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Pavilionimages from "./pavilion.js";
import Tudorimages from "./tudor.js";
import Oakwoodimages from "./oakwood1.js";

const produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const oakwoodwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Oakwood)%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const pavilionwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Pavilion)%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const tudorwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Tudor)%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="produk2">
      <div className="kartu">
        <Slider {...settings}>
          {Oakwoodimages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Oakwood ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah1">Oakwood</div>
            <div className="sub-judul-rumah">
              <FontAwesomeIcon color="red" icon={faLocationDot} /> Shila, Depok
            </div>
            <div className="cicilan">
              <div className="start">Start From</div>
              <div className="det">
                <div className="angka">13</div>
                <div className="ket">Juta/Bulan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 112m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 136m²</span>
          <FontAwesomeIcon icon={faBed} />
          <span>: 3</span>
          <FontAwesomeIcon icon={faShower} />
          <span>: 3</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={oakwoodwa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
      <div className="kartu">
        <Slider {...settings}>
          {Pavilionimages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Pavilion ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah1">Pavilion</div>
            <div className="sub-judul-rumah">
              <FontAwesomeIcon color="red" icon={faLocationDot} /> Shila, Depok
            </div>
            <div className="cicilan">
              <div className="start">Start From</div>
              <div className="det">
                <div className="angka">14</div>
                <div className="ket">Juta/Bulan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 128m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 149m²</span>
          <FontAwesomeIcon icon={faBed} />
          <span>: 4</span>
          <FontAwesomeIcon icon={faShower} />
          <span>: 3</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={pavilionwa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
      <div className="kartu">
        <Slider {...settings}>
          {Tudorimages.map((image, index) => (
            <img
              className="kartugambar"
              key={index}
              src={image}
              alt={`Tudor ${index + 1}`}
            />
          ))}
        </Slider>
        <div className="container-title-card">
          <div className="titlecard">
            <div className="judul-rumah1">Tudor</div>
            <div className="sub-judul-rumah">
              <FontAwesomeIcon color="red" icon={faLocationDot} /> Shila, Depok
            </div>
            <div className="cicilan">
              <div className="start">Start From</div>
              <div className="det">
                <div className="angka">14</div>
                <div className="ket">Juta/Bulan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="gridspek">
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          <span> : 144m²</span>
          <FontAwesomeIcon icon={faHouse} />
          <span> : 179m²</span>
          <FontAwesomeIcon icon={faBed} />
          <span>: 4</span>
          <FontAwesomeIcon icon={faShower} />
          <span>: 4</span>
        </div>
        <div className="containerwhatsapp">
          <button onClick={tudorwa} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default produk2;
