import React from "react";
import "./promo.scss";
import Promoimage from "../../media/design-hardsel-34-19-819x1024.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Promo = () => {
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Promo)%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container-promo">
      <div id="promo" className=" container-listpromo">
        <div className="containercontent">
          <div id="promo" className="judul-promo">
            PROMO KESELURUHAN SHILA AT SAWANGAN
          </div>
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Siap Huni</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Tanpa DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Biaya-Biaya
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Biaya Surat BPHTB
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Subsidi Biaya Akad 3%
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>&nbsp;&nbsp;Private Lift</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Sanitary Kohler
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheck} />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;Free Smartdoor Lock
              </span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <div className="button-wa-promo">
            <button onClick={wapromo} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Get More Promo
            </button>
          </div>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={Promoimage} alt="hardsell" />
        </div>
      </div>
    </div>
  );
};

export default Promo;
