import Tudor1 from "../../../media/produk/tudor/tudor.png";
import Tudor2 from "../../../media/produk/tudor/tudor (2).png";
import Tudor3 from "../../../media/produk/tudor/tudor (3).png";
import Tudor4 from "../../../media/produk/tudor/tudor (4).png";
import Tudor5 from "../../../media/produk/tudor/tudor (5).png";
import Tudor6 from "../../../media/produk/tudor/tudor (6).png";
import Tudor7 from "../../../media/produk/tudor/tudor (7).png";
import Tudor8 from "../../../media/produk/tudor/tudor (8).png";
import Tudor9 from "../../../media/produk/tudor/tudor (9).png";

const tudorimages = [
  Tudor1,
  Tudor2,
  Tudor3,
  Tudor4,
  Tudor5,
  Tudor6,
  Tudor7,
  Tudor8,
  Tudor9,
];

export default tudorimages;
