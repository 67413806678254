import React from "react";
import "./aboutus.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const aboutus = () => {
  const buttonabout = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281317050838&text=Halo+Tama%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20()%20https://www.shila-atsawangan.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="container">
      <div className="container-about">
        <div>
          <h2>#About us</h2>
        </div>
        <h1>
          JAKARTA'S SOUTH
          <br /> LAKE LIVING.
        </h1>
        <p>
          Shila at Sawangan adalah proyek pengembangan di Depok, Jawa Barat yang
          menggabungkan pemukiman, perkantoran, dan area komersial. Shila at
          Sawangan dibangun di atas lahan 102 hektare di sekitar lapangan golf
          Sawangan, lapangan golf tertua kedua di Jabodetabek.
        </p>
        <div className="container-buttonabout">
          <button onClick={buttonabout} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> More Info
          </button>
        </div>
      </div>
    </div>
  );
};

export default aboutus;
