import Couryard1 from "../../../media/produk/courtyard/Courtyard.png";
import Couryard2 from "../../../media/produk/courtyard/courtyard (2).png";
import Couryard3 from "../../../media/produk/courtyard/courtyard (3).png";
import Couryard4 from "../../../media/produk/courtyard/courtyard (4).png";
import Couryard5 from "../../../media/produk/courtyard/courtyard (5).png";
import Couryard6 from "../../../media/produk/courtyard/courtyard (6).png";
import Couryard7 from "../../../media/produk/courtyard/courtyard (7).png";

const couryardimages = [
  Couryard1,
  Couryard2,
  Couryard3,
  Couryard4,
  Couryard5,
  Couryard6,
  Couryard7,
];

export default couryardimages;
