import Sinsa1 from "../../../media/produk/sinsa/sinsa (1).png";
import Sinsa2 from "../../../media/produk/sinsa/sinsa (2).png";
import Sinsa3 from "../../../media/produk/sinsa/sinsa (3).png";
import Sinsa4 from "../../../media/produk/sinsa/sinsa (4).png";
import Sinsa5 from "../../../media/produk/sinsa/sinsa (5).png";
import Sinsa6 from "../../../media/produk/sinsa/sinsa (6).png";
import Sinsa7 from "../../../media/produk/sinsa/sinsa (7).png";
import Sinsa8 from "../../../media/produk/sinsa/sinsa (8).png";
import Sinsa9 from "../../../media/produk/sinsa/sinsa (9).png";
import Sinsa10 from "../../../media/produk/sinsa/sinsa (10).png";

const sinsaimages = [
  Sinsa1,
  Sinsa2,
  Sinsa3,
  Sinsa4,
  Sinsa5,
  Sinsa6,
  Sinsa7,
  Sinsa8,
  Sinsa9,
  Sinsa10,
];

export default sinsaimages;
