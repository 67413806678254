import React from "react";
import "./header.scss";

const header = () => {
  return (
    <div className="container-header">
      <div className="tittle">SHILA AT SAWANGAN</div>
      <div className="background-container"></div>
    </div>
  );
};

export default header;
