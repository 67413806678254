import React from "react";
import "./lokasi.scss";
import Lokasiimages from "./lokasi.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const lokasi = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  return (
    <div className="container-lokasi">
      <div id="lokasi" className="judul-lokasi">
        OUR LOCATION
      </div>
      <div className="carousel-lokasi">
        <Slider {...settings}>
          {Lokasiimages.map((image, index) => (
            <img
              className="kartugambar2"
              key={index}
              src={image}
              alt={`Courtyard ${index + 1}`}
            />
          ))}
        </Slider>
      </div>
      <div className="maps">
        <div className="img-map"></div>
      </div>
    </div>
  );
};

export default lokasi;
