import Hannam1 from "../../../media/produk/hannam/hannam.png";
import Hannam2 from "../../../media/produk/hannam/hannam (2).png";
import Hannam3 from "../../../media/produk/hannam/hannam (3).png";
import Hannam4 from "../../../media/produk/hannam/hannam (4).png";
import Hannam5 from "../../../media/produk/hannam/hannam (5).png";
import Hannam6 from "../../../media/produk/hannam/hannam (6).png";
import Hannam7 from "../../../media/produk/hannam/hannam (7).png";
import Hannam8 from "../../../media/produk/hannam/hannam (8).png";
import Hannam9 from "../../../media/produk/hannam/hannam (9).png";
import Hannam10 from "../../../media/produk/hannam/hannam (10).png";
import Hannam11 from "../../../media/produk/hannam/hannam (11).png";
import Hannam12 from "../../../media/produk/hannam/hannam (12).png";
import Hannam13 from "../../../media/produk/hannam/hannam (13).png";
import Hannam14 from "../../../media/produk/hannam/hannam (14).png";
import Hannam15 from "../../../media/produk/hannam/hannam (15).png";
import Hannam16 from "../../../media/produk/hannam/hannam (16).png";
import Hannam17 from "../../../media/produk/hannam/hannam (17).png";
import Hannam18 from "../../../media/produk/hannam/hannam (18).png";
import Hannam19 from "../../../media/produk/hannam/hannam (19).png";

const hannamimages = [
  Hannam1,
  Hannam2,
  Hannam3,
  Hannam4,
  Hannam5,
  Hannam6,
  Hannam7,
  Hannam8,
  Hannam9,
  Hannam10,
  Hannam11,
  Hannam12,
  Hannam13,
  Hannam14,
  Hannam15,
  Hannam16,
  Hannam17,
  Hannam18,
  Hannam19,
];

export default hannamimages;
