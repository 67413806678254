import Verdant1 from "../../../media/produk/verdant/Verdant.png";
import Verdant2 from "../../../media/produk/verdant/verdant (2).png";
import Verdant3 from "../../../media/produk/verdant/verdant (3).png";
import Verdant4 from "../../../media/produk/verdant/verdant (4).png";
import Verdant5 from "../../../media/produk/verdant/verdant (5).png";
import Verdant6 from "../../../media/produk/verdant/verdant (6).png";
import Verdant7 from "../../../media/produk/verdant/verdant (7).png";
import Verdant8 from "../../../media/produk/verdant/verdant (8).png";
import Verdant9 from "../../../media/produk/verdant/verdant (9).png";

const verdantimages = [
  Verdant1,
  Verdant2,
  Verdant3,
  Verdant4,
  Verdant5,
  Verdant6,
  Verdant7,
  Verdant8,
  Verdant9,
];

export default verdantimages;
