import Oakwood1 from "../../../media/produk/oakwood/oakwood.png";
import Oakwood2 from "../../../media/produk/oakwood/oakwood (2).png";
import Oakwood3 from "../../../media/produk/oakwood/oakwood (3).png";
import Oakwood4 from "../../../media/produk/oakwood/oakwood (4).png";
import Oakwood5 from "../../../media/produk/oakwood/oakwood (5).png";
import Oakwood6 from "../../../media/produk/oakwood/oakwood (6).png";
import Oakwood7 from "../../../media/produk/oakwood/oakwood (7).png";
import Oakwood8 from "../../../media/produk/oakwood/oakwood (8).png";
import Oakwood9 from "../../../media/produk/oakwood/oakwood (9).png";
import Oakwood10 from "../../../media/produk/oakwood/oakwood (10).png";

const oakwoodimages = [
  Oakwood1,
  Oakwood2,
  Oakwood3,
  Oakwood4,
  Oakwood5,
  Oakwood6,
  Oakwood7,
  Oakwood8,
  Oakwood9,
  Oakwood10,
];

export default oakwoodimages;
